import React from 'react';

 class App extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        bpi: {},
        time: {}
      };
  }

  componentDidMount() {
    document.title = "Users";
    fetch(process.env.REACT_APP_API_URL_BASE + '/api/users')
    .then(results => {
      return results.json();
    }).then(data => {
        this.setState({
          users: data
        });
    });

    fetch('https://official-joke-api.appspot.com/random_joke')
    .then(results => {
      return results.json();
    }).then(data => {
        this.setState({
          joke: data
        });
    });

    fetch(process.env.REACT_APP_API_URL_BASE + '/api/joke')
    .then(results => {
      return results.json();
    }).then(data => {
        this.setState({
          jokeback: data
        });
    });

    fetch(process.env.REACT_APP_API_URL_BASE + '/api/buckets')
    .then(results => {
      return results.json();
    }).then(data => {
        this.setState({
          buckets: data
        });
    });



    fetch(process.env.REACT_APP_API_URL_BASE + '/api/integration-bored')
    .then(results => {
      return results.json();
    }).then(data => {
        this.setState({
          bored: data
        });
    });
  }


  render() {
    let content1 = null
    let content2 = null
    let content3 = null
    let content4 = null
    let content5 = null
    let content6 = null
    let content7 = null

    var { users } = this.state;
    var { joke } = this.state;
    var { jokeback } = this.state;
    var { buckets } = this.state;
    var { bored } = this.state;

    if (users){
      content1 = users.map((user, key) => <div>{user.nombre}</div>)
    }

    if (joke) {
      content2 = <div>{joke.setup}</div>
      content3 = <div>{joke.punchline}</div>
    }

    if (jokeback){
      content4 = <div>{jokeback.setup}</div>
      content5 = <div>{jokeback.punchline}</div>
    }

    if (buckets){
      content6 = buckets.map((b) => <div>{b.Name}</div>)
    }

    if (bored){
      content7 = <div>{bored.activity}</div>
    }
  
    return (
      <div>
        <h2>Test nuddov2: Data from database through nuddov2 API</h2>
        <h3>"users" table from "test" schema</h3>
        {content1}

        <h2>Test nuddov2: Data from external API</h2>
        <h3>Joke API</h3>
        {content2}
        {content3}

        <h2>Test nuddov2: Data from external API through nuddov2 API</h2>
        <h3>Joke API</h3> 
        {content4}
        {content5}

        <h2>Test nuddov2: Data from S3 through nuddov2 API</h2>
        <h3>Current buckets list</h3>
        {content6}
        <br></br>
        {/* <button onClick={this.sends3()}>
          Create S3 folder
        </button> */}

        <h2>Test nuddov2: Data from external API through nuddov2 integration API</h2>
        <h3>Bored API</h3> 
        {content7}
      </div>
    );
  }
}

export default App;
